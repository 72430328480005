








































































@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;
@import "@/styles/container";

.hero-section {
    --grid-gap: 12px;
    --main-column: 59%;
    --secondary-column: calc(100% - var(--main-column) - var(--grid-gap));

    background-color: colors.$grey-1;

    @include bp.mobile-md {
        width: 100%;
        max-width: 414px;
        margin: auto;
    }

    @include bp.desktop-md {
        width: 100vw;
        overflow: hidden;
        position: relative;
    }
}


$columns-bottom-indent: 100px;

.hero-columns {
    display: flex;

    @include bp.mobile-md {
        flex-direction: column;
        gap: 0;

    }

    @include bp.desktop-md {
        @include container_md_lg;

        flex-direction: row;
        gap: var(--grid-gap);
        padding-bottom: $columns-bottom-indent;
    }
}

.hero-column__main {
    @include bp.mobile-md {
        width: 100%;
        padding: $container-padding-sm;
    }

    @include bp.desktop-md {
        width: var(--main-column);
    }
}

.hero-column__secondary {
    @include bp.mobile-md {
        width: 100%;
    }

    @include bp.desktop-md {
        width: var(--secondary-column);
        display: flex;
        flex-direction: column;
        justify-content: end;
        margin-bottom: -$columns-bottom-indent;
    }
}

.hero-title__main {
    font-family: tp.$font-family-secondary;
    color: colors.$primary-green;
    font-weight: 900;
    margin-bottom: 0;

    @include bp.mobile-md {
        margin-top: 40px;
        font-size: 34px;
        line-height: 38px;
    }

    @include bp.desktop-md {
        margin-top: 78px;
        font-size: 48px;
        line-height: 54px;
    }
}

.hero-title__secondary {
    color: colors.$secondary-grey;
    font-family: tp.$font-family;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.7px;

    @include bp.mobile-md {
        margin-top: 11px;
    }

    @include bp.desktop-md {
        margin-top: 1px;
    }
}

.hero-description {
    @include bp.mobile-md {
        margin-top: 13px;
    }

    @include bp.desktop-md {
        margin-top: 21px;
    }
}

.hero-cases-count-container {
    @include bp.mobile-md {
        margin-top: 11px;
    }

    @include bp.desktop-md {
        margin-top: 24px;
        width: 360px;
    }
}

.cases-count {
    background-color: colors.$generaptor-brand-light;
    border-radius: 8px;
    padding: 24px;
}

.cases-count__prefix {
    color: colors.$secondary-grey;
    font-family: tp.$font-family;
    font-weight: 500;
    font-size: tp.$font-size-smaller;
    line-height: 21px;
    letter-spacing: -0.21px;
}

.cases-count__num {
    font-family: tp.$font-family-secondary;
    font-weight: 900;
    color: colors.$primary-green;
    font-size: 24px;
    line-height: 27px;
    margin-top: 8px;
}

.hero-action-container {
    @include bp.mobile-md {
        margin-top: 11px;
    }

    @include bp.desktop-md {
        margin-top: 25px;
        width: 248px;
    }
}

.hero-generaptor {
    @include bp.mobile-md {
        width: 100%;
        height: 233px;
        overflow: hidden;
        position: relative;
    }

    @include bp.desktop-md {
        position: absolute;

        // TODO: 20px - голова совпадает с макетом; 27px - c низу не торчат куски фона
        // top: 20px;
        top: 27px;
        left: 51.4%;
        z-index: 0;
        width: 61vw;
        max-width: 879px;
    }

    img {
        @include bp.mobile-md {
            position: absolute;
            left: -15%;
            top: -5%;
            width: 157%;
        }

        @include bp.desktop-md {
            width: 100%;
        }
    }
}

.hero-banner-container {
    @include bp.desktop-md {
        position: relative;
        z-index: 1;
    }
}

.banner {
    border-radius: 10px 10px 0 0;
    background: colors.$ovdinfo-brown;

    @include bp.mobile-md {
        padding: 30px 16px;
    }

    @include bp.desktop-md {
        padding: 30px 40px;
    }
}

.banner__text {
    color: colors.$white;
    font-family: tp.$font-family;
    font-weight: 500;
    font-size: tp.$font-size-normal;
    line-height: 24px;

    em {
        font-style: normal;
        font-weight: 900;
        color: colors.$secondary-green;
        text-transform: uppercase;
    }
}
